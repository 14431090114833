import React, { useEffect, useState } from "react"
import { Link } from "gatsby"

const Modal = props => {
  const [unchecked, setunchecked] = useState("true")
 
  const closeOnEscapeKeyDown = e => {
    if ((e.charCode || e.keyCode) === 27) {
      props.onClose()
    }
  }

  useEffect(() => {
    document.body.addEventListener("keydown", closeOnEscapeKeyDown)
    return function cleanup() {
      document.body.removeEventListener("keydown", closeOnEscapeKeyDown)
    }
  })

  if (!props.show) {
    return null
  }

  return (
    <div
      className="fixed bg-black bg-opacity-50 w-full h-full overflow-scroll z-10"
      onClick={props.onClose}
    >
      <div
        className="mx-6 md:mx-auto my-12 md:max-w-screen-sm bg-white divide-y-2 divide-yellow-100 rounded-md"
        onClick={e => e.stopPropagation()}
      >
        <div className="px-4 flex justify-between pt-3 pb-3 bg-yellow-50 rounded-t-md">
          <h4 className="text-lg font-bold text-green-800">
            {props.title.charAt(0).toUpperCase()}
            {props.title.slice(1)}
          </h4>
          <button onClick={props.onClose}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 text-green-900"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
        <div className="modal-body px-4 py-2">
          <p className="mb-3">
            Fyll ut skjemaet under for {props.title}.<br />
            <span className="text-sm text-gray-600">(Om det er behov for det vil vi svare tilbake med eit tilbod så snart som mogleg)</span>
            <br />
            Du kan og ev. sende oss e-post på:{" "}
            <a
              className="text-green-800 hover:text-green-600"
              href="mailto:post@jolsteradventure.no"
            >
              post@jolsteradventure.no
            </a>
            <br />
            <span className="text-gray-600 text-sm">
              Vi vil berre bruke opplysningane som du sender inn for å
              administerere bestillingen. For meir info sjå{" "}
              <Link
                className="text-green-800 hover:text-green-600"
                to="/personvernerklering/"
              >
                personvernerklæringa
              </Link>{" "}
              vår.
            </span>
          </p>
          <form
            className=""
            action="../../send_epost.php"
            method="POST"
          >
            <input type="hidden" value={props.title} name="type" id="type" />
            <div className="mb-4">
              <label
                class="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="namn"
              >
                Namn
              </label>
              <input
                class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="namn"
                name="namn"
                type="text"
                placeholder="Namn"
              />
            </div>

            <div className="mb-4">
              <label
                class="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="epost"
              >
                Epostadresse
              </label>
              <input
                class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="epost"
                name="epost"
                type="email"
                placeholder="Epostadresse"
              />
            </div>

            <div className="mb-4">
              <label
                class="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="telefon"
              >
                Telefon
              </label>
              <input
                class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="telefon"
                name="telefon"
                type="phone"
                placeholder="Telefon"
              />
            </div>

            <div className="mb-4">
              <label
                class="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="dato"
              >
                Dato
              </label>
              <input
                class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="dato"
                name="dato"
                type="date"
                placeholder="Dato"
              />
            </div>

            <div className="mb-4">
              <label
                class="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="melding"
              >
                Melding{" "}
                <span className="text-gray-600 text-xs font-thin">
                  (om det ev. er noko vi bør vete. t.d. tal personar)
                </span>
              </label>
              <textarea
                name="melding"
                id="melding"
                class="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none"
                rows="4"
              ></textarea>
            </div>

            <div className="mb-4 flex flex-row items-baseline gap-2 ml-1">
              <input
                className=""
                onChange={() => {
                  setunchecked(unchecked === "" ? "true" : "")
                }}
                name="personernerklering"
                id="personvernerklering"
                type="checkbox"
              />
              <label
                className=" text-gray-700 text-sm transform -translate-y-0.5"
                htmlFor="personvernerklering"
              >
                Eg har lest{" "}
                <Link
                  className="text-green-800 hover:text-green-600"
                  to="/personvernerklering/"
                >
                  personvernerklæringa
                </Link>{" "}
                og samtykker i at Jølster Adventure kan behandle
                personopplysningar om meg.
              </label>
            </div>

            <div className="modal-footer px-4 flex justify-end gap-3 rounded-b-md mb-6">
              <button
                disabled={unchecked}
                className="disabled:bg-gray-400 disabled:cursor-default disabled:hover:bg-gray-400 disabled:text-gray-300 bg-green-400 hover:bg-green-500 text-green-900 rounded px-3 py-1.5 my-2"
              >
                Send bestilling
              </button>
              <button
                onClick={props.onClose}
                className="bg-red-300 hover:bg-red-400 rounded text-red-900 px-3 py-1.5 my-2"
              >
                Lukk
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default Modal
