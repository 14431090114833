import React, { useState } from "react"
import { Link } from "gatsby"
import ReactPlayer from "react-player"

import Layout from "../components/layout"
import Modal from "./modal"

import Norco from "../img/norco.jpg"
import Stibygging from "../img/stibygging.jpg"
import Cube from "../img/cube_mtb.png"
import Trek from "../img/utleige_trek.jpg"
import NorcoHT from "../img/utleige_norco.jpg"

export default function Stisykling() {
  const [show, setShow] = useState(false)
  const [title, setTitle] = useState("")

  return (
    <div>
      <Modal show={show} onClose={() => setShow(false)} title={title} />
      <Layout>
        <div>
          <img
            className="h-80 w-full object-cover object-center rounded-lg shadow-lg mt-6"
            src={Norco}
            alt="Terrengsykkel Norco Fluid HT 2 på Håheimsfjellet"
          />
        </div>
        <h1 className="text-3xl text-yellow-700 text-center mt-12 font-bold ">
          Stisykling
        </h1>

        <div className="grid lg:grid-cols-2 gap-16 mt-12 mb-12">
          <div className="flex gap-10 lg:gap-4 flex-col lg:mb-4">
            <div>
              <h2 className="text-red-700 font-semibold text-xl lg:mb-4">
                Utleige av stisykler
              </h2>
              <p className="mb-5">
                Kor kan du sykle i området? Du finner fleire stier på Jølster.
                Dei beste er på Årdalsdalen, Fyglastøylen, Sundsheia i
                Dvergsdalsdalen, Fossheimsdalen og i Håheimslia. Det er og
                mogleg å leige sykkel hjå oss og reise til Gloppen eller
                Kaupanger å sykle. Der er det mange gode stier. De finn meir
                informasjon om stiar på{" "}
                <Link
                  className="text-green-800 hover:text-green-500"
                  to="https://trailguide.net/"
                >
                  trailguide.net
                </Link>
                .
              </p>
            </div>
            {/* Utleige Trek Remedy 7  */}
            <div className="shadow-lg pl-3 rounded-lg">
              <img
                className="w-52 float-right ml-4 object-cover rounded-r-lg"
                src={Trek}
                alt="Elektrisk stisykkel til leige - Trek Remedy 7"
              />
              <div className="flex flex-col justify-between h-52 ">
                <div>
                  <h3 className="text-lg text-yellow-700 font-semibold">
                    Trek Remedy 7
                  </h3>
                  <p>Fulldempa stisykkel</p>
                  <p class="mb-3">Pris pr. dag kr. 400,-</p>
                </div>
                <button
                  onClick={() => {
                    setTitle("leige av Trek Remedy 7")
                    setShow(true)
                  }}
                  className="mb-3 mx-1 text-green-50 px-3 py-1 rounded-md bg-green-700 hover:bg-green-600"
                >
                  Leige av Trek Remedy 7
                </button>
              </div>
            </div>

            {/* Utleige Cube Stereo */}
            <div className="shadow-lg pl-3 rounded-lg">
              <img
                className="w-52 float-right ml-4 object-cover rounded-r-lg"
                src={Cube}
                alt="Elektrisk stisykkel til leige - Cube Stereo Pro"
              />
              <div className="flex flex-col justify-between h-52">
                <div>
                  <h3 className="text-lg text-yellow-700 font-semibold">
                    Cube Stereo Pro
                  </h3>
                  <p>Elektrisk Stisykkel</p>
                  <p class="mb-3">Pris pr. dag kr. 500,-</p>
                </div>
                <button
                  onClick={() => {
                    setTitle("leige Cube Stereo Pro")
                    setShow(true)
                  }}
                  class="mb-3 mx-2 text-green-50 px-3 py-1 rounded-md bg-gray-700 hover:bg-gray-600"
                >
                  Leige av Cube Stereo Pro
                </button>
              </div>
            </div>

            {/* Utleige Norco Fluid HT */}
            <div className="shadow-lg pl-3 rounded-lg">
              <img
                className="w-52 float-right ml-4 object-cover rounded-r-lg"
                src={NorcoHT}
                alt="Elektrisk stisykkel til leige - Norco Fluid HT"
              />
              <div className="flex flex-col justify-between h-52">
                <div>
                  <h3 className="text-lg text-yellow-700 font-semibold">
                    Norco Fluid HT
                  </h3>
                  <p>Hardtail terrengsykkel</p>
                  <p class="mb-3">Pris pr. dag kr. 250,-</p>
                </div>
                <button
                  onClick={() => {
                    setTitle("leige Norco Fluid HT")
                    setShow(true)
                  }}
                  class="mb-3 mx-2 text-green-50 px-3 py-1 rounded-md bg-red-700"
                >
                  Leige av Norco Fluid HT
                </button>
              </div>
            </div>
          </div>
          <div class="container">
            <h2 className="mb-4 text-red-700 font-semibold text-xl">
              Stisykling på Jølster
            </h2>
            <p className="mb-6">
              Vi veit kor dei fine stiane for stisykling er på Jølster i
              Sunnfjord. Om du er nybegynner eller likar å sykle bratte tekniske
              stiar kan vi helpe deg å finne dei beste sykkelturane.
            </p>
            <p>
              <img
                className="w-80 rounded shadow-md  my-6 mx-auto"
                src={Stibygging}
                alt="Stibygging på Håheim"
              />
              Jølster adventure held på å lage ein eigen sykkelsti på Håheim på
              Skei. Denne stien starter ved Grungen på nordenden av
              Håheimsvatnet på oppsida av E39. Sjå eigen side om{" "}
              <Link class=" text-green-800 hover:text-green-600" to="/guiding/">
                guiding
              </Link>{" "}
              om du ønskjer at vi viser deg denne stien. Du kan og låne ein av
              stisyklane og terrengsyklane vi har til utleige. Sjå og videoen
              frå stisykling i Håheimslia og på sørsida av Håheimsvatnet.
            </p>

            <div className="shadow-2xl mt-12">
              <ReactPlayer
                width="100%"
                controls="true"
                url="https://www.youtube.com/watch?v=mGHbIRkHvkw"
                playing={true}
              />
            </div>
          </div>
        </div>
      </Layout>
    </div>
  )
}
